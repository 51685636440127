
import { Component, Vue } from "vue-property-decorator";
import { ISelectOption } from "@/models/Global";
import { ISearchResult } from "@/models/Search";
import { NAV_MENU_BUSINESS } from "@/constants";
import OperationsSearch from "@/components/common/OperationsSearch.vue";
import InnerHeader from "@/components/common/InnerHeader.vue";

@Component({
  components: { OperationsSearch, InnerHeader },
})
export default class Search extends Vue {
  protected searchResults: ISearchResult[] = [];

  protected businessName(type?: number): string {
    return (
      NAV_MENU_BUSINESS.find((business) => business.typeId === type)?.name || ""
    );
  }

  protected businessIcon(type?: number): string {
    return (
      NAV_MENU_BUSINESS.find((business) => business.typeId === type)?.icon || ""
    );
  }

  protected goToResult(operation: ISelectOption) {
    this.$router.push({
      name: this.businessName(operation.type),
      query: { id: operation.value + "" },
    });
  }
}
